import React from 'react';

const NoPageFound = () => {
    return (
        <div>
            No page found
        </div>
    );
};

export default NoPageFound;