import { React, useEffect, useState } from 'react';
import baseApiUrl from '../../../utility/baseApiUrl';
import Swal from 'sweetalert2';
import { Link, Navigate } from 'react-router-dom';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import apiStoragePath from '../../../utility/apiStoragePath';
import Layout from '../../../components/Layout/Layout';
import Pagination from "react-js-pagination";

const axios = require("axios");

const Transactions = () => {

  const [transactions, setTransactions] = useState({});
  const dispatch = useDispatch();
  const imagePath = apiStoragePath();

  // load  data from server
  const loadData = (pageNumber = 1) => {

    dispatch({
      type:"fullPageLoader_update",
      payload : true
    });

    axios
      .get(baseApiUrl()+'transaction?page='+pageNumber)
      .then((res) => {
        setTransactions(res.data);
        dispatch({
          type:"fullPageLoader_update",
          payload : false
        });
      })
      .catch((err) => {
        dispatch({
          type:"fullPageLoader_update",
          payload : false
        });
      })
  };


  useEffect(() => {
    loadData();
  }, []); 


  // delete transactions
  const deleteTransactions = id => {
    // console.log(form)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        axios.delete(baseApiUrl() + 'transaction/' + id).then(res => {

          if (res.data.msg) {
            Swal.fire(
              'Deleted!',
              'Delete Success.',
              'success',
            )

            loadData()
          }

          else {
            Swal.fire(
              'Error!',
              'Server error try again.',
              'error',
            )
          }

        })
      }
    })
  }

  return (
    <Layout>
      <div className='bg-white rounded-lg '>


        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Transaction List</h3>
          <span className='button__bg px-6 py-2 rounded'>Transactions
          </span>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Seller
                </th>
                <th scope="col" className="px-6 py-3">
                  Code
                </th>
                <th scope="col" className="px-6 py-3">
                 Transactions Amount
                </th>
                <th scope="col" className="px-6 py-3">
                   Is Redeem
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {transactions?.data?.map((item, index) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                   
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item?.seller?.name}
                    </th>

                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.code}
                    </th>

                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      $ {item.transaction_amount}
                    </th>

                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                       {item.is_redeem === 0 ? (

                        <div>
                          No
                        </div>

                        ) : (
                        <div>
                          Yes
                        </div>
                        )}
                    </th>
                   

                    
                    {/* <td className="mt-2 px-6 py-4 flex gap-5 text-2xl">
                    
                      {item.payment_details ? <span className='text-[19px]'>Used</span> : (
                      
                       <>
                          <span onClick={() => deleteTransactions(item.id)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                          <Link to={`/edit-transaction/${item.id}`}> <AiOutlineEdit /></Link>
                        </>
                      )}

                    </td> */}
                  </tr>
                )
              })}

            </tbody>
          </table>

          {transactions?.total > 5 && 
                <div className='pl-3 py-4'>
                    <Pagination
                        activePage={transactions?.current_page ? transactions?.current_page : 0}
                        itemsCountPerPage={transactions?.per_page ? transactions?.per_page : 0 }
                        totalItemsCount={transactions?.total ? transactions?.total : 0}
                        onChange={(pageNumber) => {
                          loadData(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="p-first-page-link"
                        itemClassLast="p-last-page-link"
                        activeClass="p-active"
                        activeLinkClass="p-active-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
              }
        </div>
      </div>
    </Layout>
  );
};

export default Transactions;