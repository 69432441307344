// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import baseApiUrl from '../../../utility/baseApiUrl';
import formatCurrency from '../../../utility/formatCurrency';

const axios = require("axios");

const AddGiftCard = () => {

  const dispatch = useDispatch();
  let navigate = useNavigate()

  const ref = useRef();

  const [giftCardOffer, setGiftCardOffer] = useState([]);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const [formTitle] = useState('Add Gift Card');

  const [description, setDescription] = useState(''); // changeHere - stateName correction


  const [rate, setRate] = useState(''); // changeHere - stateName correction

  const [fixed_price_array, setFixedPriceArray] = useState([]); // changeHere - stateName correction

  const [image, setImage] = useState({}); // changeHere - stateName correction
  const [giftcard_offer_id, setGiftCardId] = useState(''); // changeHere - stateName correction
  const [giftCardItem, setGiftCardItem] = useState({}); // changeHere - stateName correction
  const [price, setPrice] = useState(''); // changeHere - stateName correction
  const [price_type, setPriceType] = useState(''); // changeHere - stateName correction
  const [card_number, setCardNumber] = useState(''); // changeHere - stateName correction


  const getGiftCardOfferData = giftCardItem_id => {

    setGiftCardId(giftCardItem_id)

    const result = giftCardOffer.find(item => item.id == giftCardItem_id);
    setGiftCardItem(result);

    // 1 for fixed price data
    if (result.price_type === 1) {
      const priceArray = JSON.parse(result.fixed_price);
      setFixedPriceArray(priceArray);

    } else {
      setFixedPriceArray([]);
    }

  }




  const onChangePrice = e_price => {

    const price = parseInt(e_price);
    setPrice(price);
    console.log('price', price);


    // getting rate
    if (giftCardItem.rate_type === 0) {

      const rate = parseInt(giftCardItem.rate);
      const amount = price * rate / 100;
      setRate(amount);
      console.log('get_rate', amount);

    } else {
      setRate(giftCardItem.rate)
      console.log('fied_get_rate', giftCardItem.rate);
    }

  }


  // handleMultipleImage
  let giftcardImages = [];

  const [galleryImages, setGalleryImages] = useState([]);

  const handleMultipleImage = e => {

    let droppedFiles = e.target.files;

    if (!droppedFiles) return;

    ([...droppedFiles]).forEach(f => {
      giftcardImages.push(f);
    });

    setGalleryImages([...giftcardImages, ...galleryImages]);

  }


  // remove Gallery Image function
  const removeGalleryImage = image => {
    let index = galleryImages.indexOf(image);
    galleryImages.splice(index, 1);

    let p_Galley_Im_i = giftcardImages.indexOf(image);
    giftcardImages.splice(p_Galley_Im_i, 1);

    setGalleryImages([...giftcardImages, ...galleryImages]);
  };


  const resetForm = () => {
    setGiftCardId('');
    setCardNumber('');
    setPrice('');
    setRate('');
    setDescription('');
    setFixedPriceArray([]);
    setGiftCardItem({});

    ref.current.value = "";
    setGalleryImages([]);

    giftcardImages = [];
  }


  const submitForm = () => {

    dispatch({
      type: "fullPageLoader_update",
      payload: true
    });


    let data = new FormData();
    data.append('giftcard_offer_id', giftcard_offer_id);
    data.append("card_number", card_number);
    data.append("price", price);

    data.append("rate", rate);
    data.append("description", description);

    if (galleryImages) {

      for (let i = 0; i < galleryImages.length; i++) {
        let file = galleryImages[i];
        data.append('gallery[' + i + ']', file || '');
      }
    };

    axios.post(baseApiUrl() + 'gift-card', data).then(res => {

      console.log(res)

      Toast.fire({
        icon: 'success',
        title: res.data.msg
      });

      dispatch({
        type: "fullPageLoader_update",
        payload: false
      });

      resetForm();
      navigate("/gift-cards");

    }).catch((err) => {

      dispatch({
        type: "fullPageLoader_update",
        payload: false
      });

      console.log(err);

      Toast.fire({
        icon: 'error',
        title: 'server error'
      });
    });

  }


  const onSubmit = e => {

    e.preventDefault();
   
    
    // window.location.pathname = "add-gift-cards";
   

    if (giftCardItem?.price_type === 0) {

      if (price >= giftCardItem.min_price && price <= giftCardItem.max_price) {
        submitForm();
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Price not in range'
        });
      }

    } else {
      submitForm();
    }

  }

  // load loadGiftCardOffer from server
  const loadGiftCardOffer = () => {
    axios
      .get(baseApiUrl() + "all-gift-card-offer")
      .then((res) => {
        setGiftCardOffer(res.data);
        console.log('setGiftCardOffer', res.data)
      })
  };

  useEffect(() => {
    loadGiftCardOffer();
  }, []);

  return (
    <Layout>
      <div>
        <div className='lg:px-24 gap-10'>
          <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
              <h3>{formTitle}</h3>
              <Link to={'/gift-cards'}>
                <button className='button__bg px-6 py-2 block rounded'>Back To Lists</button>
              </Link>
            </div>
            <form onSubmit={onSubmit} className='py-5 px-8'>
              <div className=''>

                <div className=''>
                  <div className="w-full mb-6">
                    <div>
                      <label className="form-label">Select Gift Card Offer</label>
                      <select onChange={e => getGiftCardOfferData(e.target.value)} value={giftcard_offer_id} className="select-field" required>
                        <option value="">Select</option>
                        {giftCardOffer?.map(item => {
                          return (
                            <option key={item} value={item.id}>{item.title}</option>
                          )
                        })}
                      </select>
                    </div>



                    <div className='mt-5 flex gap-4 flex-wrap'>

                      <div>
                        {giftCardItem?.price_type == 0 && 'Price Type : Range'}
                        {giftCardItem?.price_type == 1 && 'Price Type : Fixed'}
                      </div>

                      <div className=''>
                        {giftCardItem?.rate_type == 0 && <> Rate Type :  Percentage </>}
                        {giftCardItem?.rate_type == 1 && <> Rate Type : Fixed </>}
                      </div>

                      <div className=''>
                        {price && <> Rate : {parseInt(giftCardItem?.rate)}
                          {giftCardItem?.rate_type == 0 && <>%</>}
                          {giftCardItem?.rate_type == 1 && <></>}
                        </>}
                        {/* {price && <> Rate :  {formatCurrency(rate)} </>} */}
                      </div>

                    </div>


                  </div>


                  <div className="w-full mb-6">

                    <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>


                      {giftCardItem?.price_type == 0 && (
                        <div>
                          <div className=''>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"><span className='pr-3'>Enter Price between</span> {giftCardItem?.min_price} <span className='px-2'>-</span> {giftCardItem?.max_price}</label>
                            <input type="number"
                              value={price}
                              minLength={giftCardItem?.min_price}
                              maxLength={giftCardItem?.max_price}
                              onChange={e => onChangePrice(e.target.value)}
                              className="form-field" required />
                          </div>
                        </div>
                      )}


                      {giftCardItem?.price_type == 1 && (
                        <div>
                          <label className="form-label">Select Price</label>
                          <select onChange={e => onChangePrice(e.target.value)} value={price} className="select-field" required>
                            <option value="">Select</option>
                            {fixed_price_array?.map((item, index) => {
                              return (
                                // <option key={item} value={item}>{item}</option>
                                <option key={index} value={item}>{formatCurrency(item)}</option>
                              )
                            })}
                          </select>
                        </div>

                      )}


                    </div>



                  </div>

                  <div className='mb-6 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                    <div>

                      {/* <div className=''>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Card Number</label>
                        <input type="text"
                          value={card_number}
                          onChange={e => setCardNumber(e.target.value)}
                          className="form-field" required />
                      </div> */}


                      <div className='mt-6'>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gift Card Images</label>

                        <input multiple required type="file" ref={ref} className='border-2 rounded py-1 px-1 w-full'
                          onChange={handleMultipleImage}
                          onClick={e => (e.target.value = null)}
                        />
                      </div>
                    </div>



                    <div className='flex justify-end '>
                      {/* show gallery images */}
                      <div className="mt-5 flex flex-wrap gap-3">
                        {galleryImages?.map((item, index) =>

                          <div key={index} className="">
                            <div className="relative ">
                              <img src={URL.createObjectURL(item)} alt="" className="w-24 h-20 shadow-md  " />
                              <span onClick={() => removeGalleryImage(item)} className=" text-center cursor-pointer absolute left-0 top-0 bg-red-500 text-sm text-white rounded-full pt-0.5 h-6 w-6 ">X</span>
                            </div>
                          </div>)}
                      </div>
                    </div>

                  </div>
                  <div className='mt-2 w-full'>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Description</label>
                    <textarea className="mb-5 form-field" rows="7" cols="50"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </div>

                </div>

                <div className='flex flex-wrap gap-3 '>
                  <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                  <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                </div>
              </div>
            </form>

          </div>

        </div>
      </div>
    </Layout>
  );
};

export default AddGiftCard;