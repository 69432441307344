// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import baseApiUrl from '../../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import  Layout from '../../../components/Layout/Layout'
const axios = require("axios");

const CreateTransaction = () => {

    const dispatch = useDispatch();

    const [sellers, setSeller] = useState([]);
    let { id } = useParams();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Edit Transaction');


    const [code, setCode] = useState(''); // changeHere - stateName correction
    const [seller_id, setSellerId] = useState(''); // changeHere - stateName correction
    const [transaction_amount, setTransactionAmount] = useState(''); // changeHere - stateName correction


    
    const resetForm = () =>{
        setCode('');
        setSellerId('');
        setTransactionAmount('');
    }

     const onSubmit = e =>{

        dispatch({
            type:"fullPageLoader_update",
            payload : true
          });

        e.preventDefault();

        let data = new FormData();
        data.append('code', code);
        data.append("seller_id", seller_id);
        data.append("transaction_amount", transaction_amount);

        axios.post(baseApiUrl() + 'transaction-update/'+id, data).then(res => {
                       
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

              dispatch({
                type:"fullPageLoader_update",
                payload : false
              });

        }).catch((err) => {
            
            console.log('catch_error_11', err);
           
            dispatch({
                type:"fullPageLoader_update",
                payload : false
              });
                      
        });

     }


       // load users from server
        const loadUser = (pageNumber = 1) => {
            axios
            .get(baseApiUrl() + "seller-lists?page="+pageNumber)
            .then((res) => {
                setSeller(res.data);
            })
        };


      const  loadData = () =>{

        dispatch({
            type:"fullPageLoader_update",
            payload : true
          });

            axios.get(baseApiUrl() + 'transaction/'+id).then(res => {
               
                dispatch({
                    type:"fullPageLoader_update",
                    payload : false
                  });

                setCode(res.data.data.code);
                setSellerId(res.data.data.seller_id);
                setTransactionAmount(res.data.data.transaction_amount);

            })

        }

        useEffect(() => {
            loadData();
            loadUser();
        }, []); 


    return (
        <Layout>
        <div>
        <div className='mt-8 lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                   <h3>{formTitle}</h3>

                   <Link to={'/transactions'}>
                     <button className='button__bg px-6 py-2 block rounded'>Transactions</button>
                   </Link>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>
                   <div className=''>

                       <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                            <div className="w-full mb-6">
                                <div>
                                    <label  className="form-label">Seller</label>
                                    <select onChange={e => setSellerId(e.target.value)} value={seller_id} className="select-field" required>
                                        <option  value="">Select</option>
                                            {sellers?.data?.map(item => {
                                                return (
                                                    <option key={item} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                    </select>
                                </div>

                            </div>

                            <div className="w-full mb-6">
                                <div>
                                <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Transaction Amount</label>
                                <input type="number"
                                value={transaction_amount}
                                onChange={e => setTransactionAmount(e.target.value)}
                                className="form-field" 
                                required
                                 />
                                </div>
                            </div>
                        </div>

                        <div className="w-full mb-6">
                            <div>
                                <label  className="form-label">Code</label>
                                <input type="text"
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                className="form-field" 
                                required
                                 />
                            </div>

                        </div>


                       <div className='mt-4 flex flex-wrap gap-3 '>
                            <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                            <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                       </div>
                   </div>
               </form>
            </div>

        </div>
      </div>
        </Layout>
    );
};

export default CreateTransaction;