import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import baseApiUrl from '../../utility/baseApiUrl';
import { convertJsToMySqlDateFormat } from '../../utility/dateFormat';
import LineChart from './LineChart';
const axios = require("axios");

const FormForChart = () => {

    const dispatch = useDispatch();
    const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);
    const authUserPermission = useSelector((state) => state.authUserPermission);
    const [toggleForm, setToggleForm] = useState(false);

    const [approved, setApproved] = useState([]);
    const [rejectedData, setRejectedData] = useState([]);

    const [totalDays, setTotalDays] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [dateLabel, setDateLabel] = useState([]);

    const [approvalArray, setApprovalArray] = useState([]);
    const [rejectionArray, setRejectionArray] = useState([]);
    const [labelArray, setLabelArray] = useState([]);

    const [approvalArrayYearly, setApprovalArrayYearly] = useState([]);
    const [rejectionArrayYearly, setRejectionArrayYearly] = useState([]);
    const [labelArrayYearly, setLabelArrayYearly] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [yearList, setYearList] = useState('');









    const onSubmit = e => {

        e.preventDefault();

        // console.log(startDate,)
        // loadData()
        // prepare initial data with 7 days.
        // const dateRange = initialDateRange()
        // setStartDate(calendarDateFormat(dateRange[0]));
        // setEndDate(calendarDateFormat(dateRange[1]));
        // console.log("test_101_state", startDate, endDate)


        // loadData();
        getApprovalRejectionList(calendarDateFormat(startDate), calendarDateFormat(endDate));
    }

    // const loadChartData = () =>{
    //     let data = { start_date: startDate, end_date: endDate }
    //     axios.post(baseApiUrl() + 'chart-data', data).then(res => {

    //         if (res.data) {
    //             approvedDataLoop(diffInDays, res.data.approved);
    //             rejectionLoop(diffInDays, res.data.rajected);
    //             dateLaDabeltaLoop(diffInDays);

    //             dispatch({
    //                 type: "fullPageLoader_update",
    //                 payload: false
    //             });

    //         }

    //     }).catch(err => {
    //         console.log('chart data error', err);

    //         dispatch({
    //             type: "fullPageLoader_update",
    //             payload: false
    //         });

    //     })
    // }



    useEffect(() => {

        // var today = new Date();
        // var dd = String(today.getDate()).padStart(2, '0');
        // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        // var yyyy = today.getFullYear();


        // let date = new Date().getDate() + 10

        // let yy_mm = yyyy + '-' + mm + '-';

        // let start_today = yyyy + '-' + mm + '-' + dd;
        // let next_ten_days = yy_mm + date;


        // prepare initial data with 7 days.
        const dateRange = initialDateRange()
        setStartDate(calendarDateFormat(dateRange[0]));
        setEndDate(calendarDateFormat(dateRange[1]));
        console.log("test_101_state", startDate, endDate)


        // loadData();
        getApprovalRejectionList(calendarDateFormat(dateRange[0]), calendarDateFormat(dateRange[1]));
        setSelectedYear(new Date().getFullYear())
        getApprovalRejectionListYearly(new Date().getFullYear());
        // getApprovalRejectionList(calendarDateFormat(dateRange[0]), calendarDateFormat(dateRange[1]), findDayDifferenceFromDateRange(calendarDateFormat(dateRange[0]), calendarDateFormat(dateRange[1])));

    }, [])


    const getApprovalRejectionList = (tempStartDate, tempEndDate) => {

        dispatch({
            type: "fullPageLoader_update",
            payload: true
        });
        let sellerId = 0 
        if(authUserPermission==='seller'){
            sellerId=loggedinUserInfo.id; 
        }
        
        const data = { start_date: tempStartDate, end_date: tempEndDate, seller_id:sellerId }
        axios.post(baseApiUrl() + 'chart-data', data).then(res => {

            dispatch({
                type: "fullPageLoader_update",
                payload: false
            });
            console.log("test_102", res.data);
            console.log("test_103");
            if (res.data) {

                setLabelArray(res.data.lableArray)
                setRejectionArray(res.data.rejectionArray)
                setApprovalArray(res.data.approvalArray)

                // approvedDataLoop(diffInDays, res.data.approved,tempStartDate,tempEndDate );
                // rejectionLoop(diffInDays, res.data.rajected);
                // dateLaDabeltaLoop(diffInDays);



            }


        }).catch(err => {
            console.log('chart data error', err);

            dispatch({
                type: "fullPageLoader_update",
                payload: false
            });

        })
    }

    const calendarDateFormat = (jsDateValue) => {
        const dateObj = new Date(jsDateValue);
        return dateObj.toISOString().split('T')[0];
    }

    const initialDateRange = (dayDifference = 7) => {
        // const dayDifference = 7; 
        const startDate = new Date();
        const endDate = new Date();
        endDate.setDate(startDate.getDate() + dayDifference);
        console.log("initialDateRange", startDate, endDate);
        return [startDate, endDate]

    }
    const findDayDifferenceFromDateRange = (startDate, endDate) => {
        const tempStartDate = new Date(startDate);
        const tempEndDate = new Date(endDate);

        const differenceInTime = tempEndDate.getTime() - tempStartDate.getTime();
        const diffInDay = differenceInTime / (1000 * 3600 * 24);
        console.log("findDayDifferenceFromDateRange_1", tempStartDate, tempEndDate, diffInDay);
        return diffInDay;
    }


    const handleYearChangeValue = (yearValue) => {
        console.log("test_201", yearValue);
        setSelectedYear(yearValue)
        getApprovalRejectionListYearly(yearValue);
    }
    const getApprovalRejectionListYearly = (tempYearValue) => {

        const firstDay = new Date(tempYearValue, 0, 1);
        const lastDay = new Date(tempYearValue, 11, 31);

        const tempStartDate = convertJsToMySqlDateFormat(firstDay);
        const tempEndDate = convertJsToMySqlDateFormat(lastDay);
        console.log("test_202", tempStartDate, tempEndDate)


        dispatch({
            type: "fullPageLoader_update",
            payload: true
        });
        let sellerId =0 ; 
        if(authUserPermission==='seller'){
            sellerId=loggedinUserInfo.id; 
        }
        
        const data = { start_date: tempStartDate, end_date: tempEndDate, seller_id:sellerId }
        // const data = { start_date: tempStartDate, end_date: tempEndDate }
        axios.post(baseApiUrl() + 'chart-data-yearly', data).then(res => {

            dispatch({
                type: "fullPageLoader_update",
                payload: false
            });
            console.log("test_102", res.data);
            console.log("test_103");
            if (res.data) {

                setLabelArrayYearly(res.data.lableArray)
                setRejectionArrayYearly(res.data.rejectionArray)
                setApprovalArrayYearly(res.data.approvalArray)

                // approvedDataLoop(diffInDays, res.data.approved,tempStartDate,tempEndDate );
                // rejectionLoop(diffInDays, res.data.rajected);
                // dateLaDabeltaLoop(diffInDays);



            }


        }).catch(err => {
            console.log('chart data error', err);

            dispatch({
                type: "fullPageLoader_update",
                payload: false
            });

        })
    }

    const generateYeraList = () => {
        const currentYear = new Date().getFullYear();
        const tempYearList = [];
        for (let i = currentYear; i < currentYear + 10; i++) {
            tempYearList.push(i);
        }
        // setYearList(tempYearList)
        return tempYearList;
    }

    const arryaToValue = (array, postion) => {
        return array[postion];
    }

    // trsah method 
    const loadData = () => {

        // Calculating the no. of days between two dates


        const getNumberOfDays = (start, end) => {

            const date1 = new Date(start);
            const date2 = new Date(end);

            // One day in milliseconds
            const oneDay = 1000 * 60 * 60 * 24;

            // Calculating the time difference between two dates
            const diffInTime = date2.getTime() - date1.getTime();

            // Calculating the no. of days between two dates
            const diffInDays = Math.round(diffInTime / oneDay);

            setTotalDays(diffInDays);
            return diffInDays;
        }

        const diffInDays = getNumberOfDays(startDate, endDate);

        dispatch({
            type: "fullPageLoader_update",
            payload: true
        });


        if (diffInDays) {


            let data = { start_date: startDate, end_date: endDate }
            axios.post(baseApiUrl() + 'chart-data', data).then(res => {

                if (res.data) {
                    approvedDataLoop(diffInDays, res.data.approved);
                    rejectionLoop(diffInDays, res.data.rajected);
                    dateLaDabeltaLoop(diffInDays);

                    dispatch({
                        type: "fullPageLoader_update",
                        payload: false
                    });

                }

            }).catch(err => {
                console.log('chart data error', err);

                dispatch({
                    type: "fullPageLoader_update",
                    payload: false
                });

            })
        }

    }
    const approvedDataLoop = (number, approvedApiData, startDate, endDate) => {

        // let i = 0;
        // approvedData  loop
        let approvedData = approvedApiData;
        console.log("test_103", number, approvedApiData)


        let newApprovedData = [];

        for (let i = 0; i <= number; i++) {

            let currentDate = startDate.setDate(new Date(startDate).getDate() + i)
            console.log("test_104", approvedData[i].approval_date, currentDate)



            // let approvalItem = approvedData.find(item => new Date(item.approval_date).getDate() == currentDate);


            // if (approvalItem) {
            //     newApprovedData.push(approvalItem?.total);

            // } else {
            //     newApprovedData.push(0);
            // }

            // if (newApprovedData.length === number) {
            //     setApproved(newApprovedData);
            // }

            // i++;
        }

    }


    const rejectionLoop = (number, rejectionApiData) => {

        let i = 0;

        let rejectionData = rejectionApiData;
        let newRejectionData = [];

        while (i <= number) {

            let date = new Date(startDate).getDate() + i

            let rejection = rejectionData.find(item => new Date(item.rejection_date).getDate() == date);

            if (rejection) {
                newRejectionData.push(rejection?.total);
            } else {
                newRejectionData.push(0);
            }

            if (newRejectionData.length === number) {
                setRejectedData(newRejectionData);
            }

            i++;
        }
    }

    const dateLaDabeltaLoop = (number) => {

        let i = 0;

        let labelDate = [];

        while (i <= number) {

            let date = new Date(startDate).getDate() + i
            var mm = new Date(startDate).toLocaleString("en-US", { month: "short" });
            var yyyy = new Date(startDate).getFullYear();

            labelDate.push(`${date} ${mm} ${yyyy}`)

            if (i === number) {
                setDateLabel(labelDate)
            }

            i++;
        }

    }
    // trsah method //

    // MOth 
    const handleMonthChangeValue = (monthValue) => {
        console.log("test_201", monthValue);
        setSelectedMonth(monthValue)
        // find 1st date 
        const now = new Date();

        const firstDay = new Date(now.getFullYear(), monthValue-1, 1);
        const lastDay = new Date(now.getFullYear(), monthValue, 0);
        console.log("test_201_2", firstDay,lastDay);
        const temstartDate = convertJsToMySqlDateFormat(firstDay);
        const tempndDate = convertJsToMySqlDateFormat(lastDay);
        setStartDate(temstartDate)
        setEndDate(tempndDate)
        // find Last date 
        getApprovalRejectionList(temstartDate, tempndDate);
    }
    // MOth //

    return (

        <>

            <div>
                {/* <div className='pb-8 gap-10'>
                    <div className='pb-10 w-full bg-white rounded-md border shadow' >
                        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                            <h3>Date Filter form</h3>

                            <button onClick={() => setToggleForm(!toggleForm)} className='button__bg px-6 py-2 block rounded'>Toggle Form</button>

                        </div>
                        {toggleForm &&
                            
                        }

                    </div>

                </div> */}
                <>
                    <form onSubmit={onSubmit} className='py-5 px-8'>
                        <div className='flex gap-5 flex-wrap'>

                            <div className='flex flex-wrap gap-5 lg:w-9/12  md:w-9/12 w-full '>

                                <div className='w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                                    <div className=" mb-6">
                                        <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Start Date</label>
                                            <input type="date"
                                                value={startDate}
                                                onChange={e => setStartDate(e.target.value)}
                                                className="form-field" required />
                                        </div>

                                    </div>

                                    <div className="mb-6 ">
                                        <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">End Date</label>
                                            <input type="date"
                                                value={endDate}
                                                onChange={e => setEndDate(e.target.value)}
                                                className="form-field"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="lg:mt-[29px] md:mt-[29px] h-11 text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 font-medium rounded-lg text-sm  px-8 text-center dark:bg-blue-600 ">Search Report</button>

                        </div>
                        <div>
                            <label for="month" className="mx-10 text-xl ">Select Year</label>
                            {/* <select id="cars" className="text-xl " onChange={(e) => handleYearChangeValue(e.target.value)} value={selectedYear}>
                                {
                                    generateYeraList().map(year => {
                                        return <option value={year}>{year}</option>
                                    })
                                }
                            </select> */}
                            <select id="month" className="text-xl " onChange={(e) => handleMonthChangeValue(e.target.value)} value={selectedMonth}>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>

                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                    </form>
                    {/* <LineChart approved={approved} rejected={rejectedData} dateLabel={dateLabel} /> */}

                    <LineChart approved={approvalArray} rejected={rejectionArray} dateLabel={labelArray} />


                    <div className="bg-gray-200 flex flex-col md:flex-row justify-between py-5 border-radius-3">
                        <div>
                            <label for="cars" className="mx-10 text-xl ">Select Year</label>
                            <select id="cars" className="text-xl " onChange={(e) => handleYearChangeValue(e.target.value)} value={selectedYear}>
                                {
                                    generateYeraList().map(year => {
                                        return <option value={year}>{year}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="mx-10 text-xl ">
                            Showing Year: {selectedYear}
                        </div>

                    </div>
                    <div>

                    </div>
                    <div class="w-full flex flex-col md:flex-row">
                        <div className="w-full  md:w-9/12"><LineChart approved={approvalArrayYearly} rejected={rejectionArrayYearly} dateLabel={labelArrayYearly} /></div>
                        <div className="w-full md:w-3/12">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Month
                                        </th>
                                        <th scope="col" className=" py-3 text-right">
                                            Approval
                                        </th>
                                        <th scope="col" className=" py-3 text-right">
                                            Rejected
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='mt-5'>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => {
                                        return (
                                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                <td className="font-medium">
                                                    {arryaToValue(labelArrayYearly, index)}
                                                    {/* {  labelArrayYearly.map(item=>item)} */}
                                                </td>
                                                <td className="text-right">
                                                    {arryaToValue(approvalArrayYearly, index)}
                                                    {/* {  labelArrayYearly.map(item=>item)} */}
                                                </td>
                                                <td className="text-right">
                                                    {arryaToValue(rejectionArrayYearly, index)}
                                                    {/* {  labelArrayYearly.map(item=>item)} */}
                                                </td>
                                                {/* <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                    {item.title}
                                                </th> */}

                                                {/* //                             <td className="created_at px-6 py-4 ">
                    //                                 {item.is_active === 0 ? <>Maintenance</> : <>Active</>}
                    //                             </td> */}
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>



                </>
            </div>


        </>

    );
};

export default FormForChart;