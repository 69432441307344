// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../../../components/Layout/Layout";
import apiStoragePath from "../../../utility/apiStoragePath";
import baseApiUrl from "../../../utility/baseApiUrl";
import formatCurrency from '../../../utility/formatCurrency';

const axios = require("axios");

const EditGiftCard = () => {
  const authUserPermission = useSelector((state) => state.authUserPermission);

  const dispatch = useDispatch();
  let { id } = useParams();
  const imagePath = apiStoragePath();
  const ref = useRef();
  const commentImRef = useRef();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [giftCardOffer, setGiftCardOffer] = useState([]);

  const [showImage, setShowImage] = useState(false);

  const [formTitle] = useState("Edit Gift Card");
  const [commentData, seCommentData] = useState({}); // changeHere - stateName correction


  const [comment_image, setCheckedByImage] = useState({}); // changeHere - stateName correction
  const [comment, seComment] = useState(""); // changeHere - stateName correction

  const [price, setPrice] = useState(""); // changeHere - stateName correction

  const [description, setDescription] = useState(""); // changeHere - stateName correction
  const [card_status, setStatus] = useState(""); // changeHere - stateName correction

  const [rate, setRate] = useState(0); // changeHere - stateName correction



  const [fixed_price_array, setFixedPriceArray] = useState([]); // changeHere - stateName correction

  const [giftcard_offer_id, setGiftCardOfferID] = useState(''); // changeHere - stateName correction
  const [giftCardItem, setGiftCardItem] = useState({}); // changeHere - stateName correction

  const [card_number, setCardNumber] = useState(''); // changeHere - stateName correction

  const [sellerName, setSellerName] = useState('');
  const [sellerEmail, setSellerEmail] = useState('');


  const loadComments = () => {
    axios
      .get(baseApiUrl() + "gift-card-comments/" + id)
      .then((res) => {
        seCommentData(res.data.data);
      })
      .catch((err) => console.error(err))
      .then(() => {
        console.log("final done");
      });
  };




  const getGiftCardOfferData = giftCardItem_id => {

    console.log('giftCardOffer_2', giftCardOffer);

    const result = giftCardOffer.find(item => item.id == giftCardItem_id);
    console.log('result', result);

    setGiftCardItem(result);

    // 1 for fixed price data
    if (result.price_type === 1) {
      const priceArray = JSON.parse(result.fixed_price);
      setFixedPriceArray(priceArray);

    } else {
      setFixedPriceArray([]);
    }

  }

  const reloadPrice = () => getGiftCardOfferData(giftcard_offer_id);


  const onChangePrice = e_price => {

    const price = parseInt(e_price);
    setPrice(price);
    console.log('price', price);


    // getting rate
    if (giftCardItem.rate_type === 0) {

      const rate = parseInt(giftCardItem.rate);
      const amount = price * rate / 100;
      setRate(amount);

    } else {
      setRate(giftCardItem.rate)
    }

  }


  // handleMultipleImage
  const [oldGalleryImages, setOldGalleryImages] = useState([]);

  let giftcardImages = [];
  const [galleryImages, setGalleryImages] = useState([]);

  const handleMultipleImage = e => {

    let droppedFiles = e.target.files;

    if (!droppedFiles) return;

    ([...droppedFiles]).forEach(f => {
      giftcardImages.push(f);
    });

    setGalleryImages([...giftcardImages, ...galleryImages]);

  }


  // remove Gallery Image function
  const removeGalleryImage = image => {
    let index = galleryImages.indexOf(image);
    galleryImages.splice(index, 1);

    let p_Galley_Im_i = giftcardImages.indexOf(image);
    giftcardImages.splice(p_Galley_Im_i, 1);

    setGalleryImages([...giftcardImages, ...galleryImages]);
  };

  const submitForm = () => {


    let data = new FormData();

    data.append('giftcard_offer_id', giftcard_offer_id);
    data.append("price", price);

    data.append("rate", rate);

    data.append("description", description);
    data.append("card_number", card_number);

    data.append("card_status", card_status);

    if (galleryImages) {

      for (let i = 0; i < galleryImages.length; i++) {
        let file = galleryImages[i];
        data.append('gallery[' + i + ']', file || '');
      }
    };

    dispatch({
      type: "fullPageLoader_update",
      payload: true,
    });
    axios
      .post(baseApiUrl() + "gift-card-update/" + id, data)
      .then((res) => {
        dispatch({
          type: "fullPageLoader_update",
          payload: false,
        });
        Toast.fire({
          icon: "success",
          title: res.data.msg,
        });

        seComment("");

        setCheckedByImage({});

        ref.current.value = "";
        // setGalleryImages([]);
        // giftcardImages = [];

        if (authUserPermission !== "seller") {
          commentImRef.current.value = "";
        }

        loadComments();
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "fullPageLoader_update",
          payload: false,
        });

        Toast.fire({
          icon: "error",
          title: "server error",
        });
      });



  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (giftCardItem?.price_type === 0) {

      if (price >= giftCardItem.min_price && price <= giftCardItem.max_price) {
        submitForm();
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Price not in range'
        });
      }

    } else {
      submitForm();
    }


  };

  const addComment = (e) => {
    e.preventDefault();

    let data = new FormData();

    if (comment_image?.name) {
      data.append("comment_image", comment_image);
    }

    data.append("comment", comment);

    dispatch({
      type: "fullPageLoader_update",
      payload: true,
    });

    axios
      .post(baseApiUrl() + "add-gift-card/" + id, data)
      .then((res) => {
        dispatch({
          type: "fullPageLoader_update",
          payload: false,
        });

        Toast.fire({
          icon: "success",
          title: res.data.msg,
        });

        commentImRef.current.value = "";
        setCheckedByImage({});
        loadComments();

      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "fullPageLoader_update",
          payload: false,
        });

        Toast.fire({
          icon: "error",
          title: "server error",
        });
      });
  };


  // load loadGiftCardOffer from server
  const loadGiftCardOffer = () => {

    axios
      .get(baseApiUrl() + "all-gift-card-offer")
      .then(res => {
        setGiftCardOffer(res.data);
      })
  };

  useEffect(() => {
    loadGiftCardOffer();
  }, []);
  // useEffect(() => {
  //   if(giftcard_offer_id>0){
  //     getGiftCardOfferData(giftcard_offer_id);
  //   }

  // }, [giftcard_offer_id]);

  const getSellerInfo = (seller_id) => {

    axios
      .get(baseApiUrl() + "users/" + seller_id)
      .then((res) => {
        dispatch({
          type: "fullPageLoader_update",
          payload: false,
        });

        const data = res.data.data;
        console.log('data-102', data.name, data.email);
        setSellerName(data.name);
        setSellerEmail(data.email);




      })
      .catch((err) => {
        dispatch({
          type: "fullPageLoader_update",
          payload: false,
        });
      })
      .then(() => {
        console.log("final done");
      });
  }

  const loadData = () => {
    dispatch({
      type: "fullPageLoader_update",
      payload: true,
    });

    axios
      .get(baseApiUrl() + "gift-card/" + id)
      .then((res) => {
        dispatch({
          type: "fullPageLoader_update",
          payload: false,
        });

        const data = res.data.data;
        console.log('data-101', data);
        setSellerName(data.name);
        setSellerEmail(data.email);

        setDescription(data.description);
        setPrice(data.price);

        setCardNumber(data.card_number);

        setStatus(data.card_status);
        setRate(data.rate);

        setGiftCardOfferID(data.giftcard_offer_id);


        let gallery_img = [...oldGalleryImages]
        gallery_img = res.data.images;
        setOldGalleryImages(res.data.images)


        setTimeout(() => {
          console.log("hello101")
          getGiftCardOfferData(data.giftcard_offer_id);
        }, 1300)



      })
      .catch((err) => {
        dispatch({
          type: "fullPageLoader_update",
          payload: false,
        });
      })
      .then(() => {
        console.log("final done");
      });
  };




  useEffect(() => {
    loadData();
    loadComments();
  }, [id]);


  const removeOldGalleryImage = (old_galler_id, old_galler_index) => {

    console.log('old_galler_index', old_galler_index);

    let tem_data = [...oldGalleryImages];
    tem_data.slice(old_galler_index, 1);
    setOldGalleryImages(tem_data);

    axios.delete(baseApiUrl() + "delete-giftcard-image/" + old_galler_id)
      .then((res) => {
        if (res.data.msg) {
          loadData();
        } else {
          Swal.fire("Error!", "Server error try again.", "error");
        }
      });

  }

  // deleteComment of giftCard
  const deleteComment = (id) => {
    // console.log(form)

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(baseApiUrl() + "delete-giftcard-comment/" + id)
          .then((res) => {
            if (res.data.msg) {
              Swal.fire("Deleted!", "Delete Success.", "success");

              loadComments();
            } else {
              Swal.fire("Error!", "Server error try again.", "error");
            }
          });
      }
    });
  };

  return (
    <Layout>
      {showImage && (
        <div className="popup__background">
          <div className="bg-white form__center w-100 rounded">
            <div className="py-5 px-8">
              <div className="flex justify-between ">
                <h1>Full Image</h1>
                <button
                  onClick={() => setShowImage(false)}
                  className="text-lg text-red-400"
                >
                  X
                </button>
              </div>
              <img
                src={imagePath + showImage}
                alt=""
                className="mt-4 w-full shadow-sm object-cover "
              />
            </div>
          </div>
        </div>
      )}


      <div>
        <div className="lg:px-24 md:px-10  gap-10">
          <div className="w-full bg-white rounded-md border shadow">
            <div className="px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between ">
              <h3>{formTitle}</h3>
              <button onClick={reloadPrice} className="button__bg px-6 py-2 rounded">
                Show Price
              </button>

              <Link to={'/gift-cards'}>
                <button className='button__bg px-6 py-2 block rounded'>Back To Lists</button>
              </Link>
            </div>
            <form onSubmit={onSubmit} className="py-5 px-8">
              <div className="">
                <div className="">
                  <div className="w-full mb-6">
                    <div className="mb-6">
                      <p>
                        Seller Name : {sellerName} <span className="pl-10">Email : {sellerEmail}</span>
                      </p>
                    </div>
                    <div>
                      <label className="form-label">Select Gift Card Offer</label>
                      <select onChange={e => getGiftCardOfferData(e.target.value)} value={giftcard_offer_id} className="select-field" required>
                        <option value="">Select</option>
                        {giftCardOffer?.map(item => {
                          return (
                            <option key={item} value={item.id}>{item.title}</option>
                          )
                        })}
                      </select>
                    </div>

                    <div className='mt-5 flex gap-4 flex-wrap'>

                      <div>
                        {giftCardItem?.price_type == 0 && 'Price Type : Range'}
                        {giftCardItem?.price_type == 1 && 'Price Type : Fixed'}
                      </div>

                      <div className=''>
                        {giftCardItem?.rate_type == 0 && <> Rate Type :  Percentage </>}
                        {giftCardItem?.rate_type == 1 && <> Rate Type : Fixed </>}
                      </div>

                      <div className=''>
                        {price && <> Rate :  {formatCurrency(rate)} </>}
                      </div>

                    </div>

                  </div>

                  <div className="w-full mb-6">
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1">
                      <div>
                        <div>

                          {giftCardItem?.price_type == 0 && (
                            <div>
                              <div className='mt-5'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"><span className='pr-3'>Enter Price between</span> {giftCardItem?.min_price} <span className='px-2'>-</span> {giftCardItem?.max_price}</label>
                                <input type="number"
                                  value={price}
                                  minLength={giftCardItem?.min_price}
                                  maxLength={giftCardItem?.max_price}
                                  onChange={e => onChangePrice(e.target.value)}
                                  className="form-field" />
                              </div>
                            </div>
                          )}


                          {giftCardItem?.price_type == 1 && (
                            <div className="mt-5">
                              <label className="form-label">Select Price - (Price {price}) </label>
                              <select onChange={e => onChangePrice(e.target.value)} value={price} className="select-field" required>
                                <option value="">Select</option>
                                {fixed_price_array?.map(item => {
                                  return (
                                    // <option key={item} value={item}>{item}</option>
                                    <option key={item} value={item}>{formatCurrency(item)}</option>
                                  )
                                })}
                              </select>
                            </div>

                          )}

                          <div className='mt-5'>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Card Number</label>
                            {
                              authUserPermission === 'seller' ? <input type="text"
                                readOnly
                                value={card_number}
                                onChange={e => setCardNumber(e.target.value)}
                                className="form-field" />
                                :
                                <input type="text"
                                  
                                  value={card_number}
                                  onChange={e => setCardNumber(e.target.value)}
                                  className="form-field" />

                            }

                          </div>

                          <div className='mt-6'>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gift Card Images</label>

                            <input multiple type="file" ref={ref} className='border-2 rounded py-1 px-1 w-full'
                              onChange={handleMultipleImage}
                              onClick={e => (e.target.value = null)}
                            />
                          </div>
                        </div>


                      </div>

                      <div className="flex justify-end ">

                        {/* show old gallery images */}
                        <div className="mt-5 flex flex-wrap gap-3">
                          {oldGalleryImages?.map((item, index) =>

                            <div key={index} className="">
                              <div className="relative ">
                                <img src={imagePath + item.image} alt="" className="w-24 h-20 shadow-md  " />
                                <span onClick={() => removeOldGalleryImage(item.id, index)} className=" text-center cursor-pointer absolute left-0 top-0 bg-red-500 text-sm text-white rounded-full pt-0.5 h-6 w-6 ">X</span>
                                <span onClick={() => setShowImage(item.image)} className=" text-center cursor-pointer absolute right-0 top-0 bg-green-800 text-sm text-white rounded-full pt-0.5 h-6 w-10">View</span>
                              </div>
                            </div>)}
                        </div>


                        {/* show new gallery images */}
                        <div className="px-3 mt-5 flex flex-wrap gap-3">
                          {galleryImages?.map((item, index) =>

                            <div key={index} className="">
                              <div className="relative ">
                                <img src={URL.createObjectURL(item)} alt="" className="w-24 h-20 shadow-md  " />
                                <span onClick={() => removeGalleryImage(item)} className=" text-center cursor-pointer absolute left-0 top-0 bg-red-500 text-sm text-white rounded-full pt-0.5 h-6 w-6 ">X</span>
                              </div>
                            </div>)}
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Description
                    </label>
                    <textarea
                      className="mb-5 form-field"
                      rows="10"
                      cols="50"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  {authUserPermission !== "seller" && (
                    <div className="border-t-2  my-7">
                      <h1 className="mt-3 text-lg">Admin Option</h1>
                      <div className="mt-5 w-full">
                        <label className="form-label">Card Status</label>
                        <select
                          onChange={(e) => setStatus(e.target.value)}
                          value={card_status}
                          className="select-field"
                        >
                          <option value="">Select</option>
                          <option value="0">Pending</option>
                          <option value="1">Approved</option>
                          <option value="2">Rejected</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-wrap gap-3 ">
                  <button
                    type="submit"
                    className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Save
                  </button>

                </div>
              </div>
            </form>
          </div>
        </div>


        {/* comment box */}
        <div className="mt-10 lg:px-24 md:px-10  gap-10">
          <div className="w-full bg-white rounded-md border shadow">
            <div className="px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between ">
              <h3>Comment Box</h3>
              <button className="button__bg px-6 py-2 rounded">
                <Link to={"/gift-cards"}>Back To Lists</Link>
              </button>
            </div>
            <form onSubmit={addComment} className="py-5 px-8">

              <div className="flex gap-4 flex-wrap justify-between ">
                <div>
                  <label className="mt-2 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Full image
                  </label>
                  <input
                    type="file"
                    ref={commentImRef}
                    className="border-2 rounded py-1 px-1 w-full"
                    onChange={(e) => setCheckedByImage(e.target.files[0])}
                    onClick={(e) => (e.target.value = null)}
                  />
                </div>

                <div className="">
                  {comment_image.name && (
                    <div className="mt-3">
                      <img
                        src={URL.createObjectURL(comment_image)}
                        alt=""
                        className="w-full h-40 shadow-sm object-cover "
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-5">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Comment
                </label>
                <textarea
                  className="mb-5 form-field"
                  rows="10"
                  cols="50"
                  value={comment}
                  onChange={(e) => seComment(e.target.value)}
                />
              </div>
              <button type="submit" className="mt-6 save-btn form__btn__bg">Comment Now</button>

            </form>
          </div>
        </div>


        {/* comment data */}
        {commentData?.data?.length > 0 ? (
          <div className="mt-10 lg:px-24 md:px-10">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full bg-white text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="border text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Image
                    </th>

                    <th scope="col" className="px-6 py-3">
                      Comment
                    </th>

                    <th scope="col" className="px-6 py-3">
                      User
                    </th>

                    <th scope="col" className="px-6 py-3 ">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="mt-5">
                  {commentData?.data?.map((item) => {
                    return (
                      <tr
                        key={item}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <th
                          scope="row"
                          className=" w-10 px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                          {item.image ? (
                            <img
                              src={imagePath + item.image}
                              onClick={() => setShowImage(item.image)}
                              className="border-2 h-12 object-cover cursor-pointer "
                            />
                          ) : (
                            <p className="border p-3 ">No Image</p>
                          )}
                        </th>
                        <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                          {item.comment}
                        </th>

                        <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                          {item?.comment_user?.name}
                        </th>

                        <td className="mt-2 px-6 py-4 flex gap-5 text-2xl">
                          <span
                            onClick={() => deleteComment(item.id)}
                            className=" cursor-pointer"
                          >
                            {" "}
                            <AiOutlineDelete />{" "}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {commentData?.total > 5 && (
                <div className="pl-3 py-4">
                  <Pagination
                    activePage={
                      commentData?.current_page ? commentData?.current_page : 0
                    }
                    itemsCountPerPage={
                      commentData?.per_page ? commentData?.per_page : 0
                    }
                    totalItemsCount={
                      commentData?.total ? commentData?.total : 0
                    }
                    onChange={(pageNumber) => {
                      loadData(pageNumber);
                    }}
                    pageRangeDisplayed={8}
                    itemClass="page-item"
                    linkClass="page-link"
                    itemClassFirst="p-first-page-link"
                    itemClassLast="p-last-page-link"
                    activeClass="p-active"
                    activeLinkClass="p-active-link"
                    firstPageText="First Page"
                    lastPageText="Last Lage"
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <p className="mt-10 lg:px-24 md:px-10 text-lg">No Comment yet</p>
        )}
      </div>
    </Layout>
  );
};

export default EditGiftCard;
