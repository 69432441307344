import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import baseApiUrl from '../../../utility/baseApiUrl';

import Pagination from "react-js-pagination";

const axios = require("axios");
const CardOfferComponent = () => {
    const [giftCardOffer, setGiftCardOffer] = useState({});

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.pageLoaderReducer);


  // load giftCardOffer from server
  const loadUser = (pageNumber = 1) => {
    dispatch({
      type:"fullPageLoader_update",
      payload : true
    });
    axios

      .get(baseApiUrl() + "gift-card-offer?page="+pageNumber)
      .then((res) => {
        
        dispatch({
          type:"fullPageLoader_update",
          payload : false
        });
        setGiftCardOffer(res.data);
        // console.log(test2)

      })
      .catch((err) => {
        dispatch({
          type:"fullPageLoader_update",
          payload : false
        });
      })


    console.log('test-5', isLoading)
  };


  useEffect(() => {
    

    loadUser();
  }, []); 

  



  return (
    <>
      <div className='bg-white rounded-lg '>

        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Gift Card Offer List</h3>
             {/* <Link to={'/gift-card-offer'}>
                     <button className='button__bg px-6 py-2 block rounded'>View Card Offer List</button>
              </Link> */}
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Card Name
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  Rate
                </th> */}
                <th scope="col" className="px-6 py-3 ">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Rate
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {giftCardOffer?.data?.map((item, index) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.title}
                    </th>
                    {/* <td className="px-6 py-4 capitalize created_at">
                      {item.rate_type === 0 ? <>{item.rate} %</> : <>{item.rate} Fixed</>}
                    </td> */}
                    <td className="created_at px-6 py-4 ">
                       {item.is_active === 0 ? <>Maintenance</> : <>Active</>}
                    </td>
                    <td className="created_at px-6 py-4 ">
                        {item.rate}{item.rate_type === 0 ? <> %</> : <> Fixed</>}
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>

            {giftCardOffer?.total > 10 && 
                 <div className='pl-3 py-4'>
                    <Pagination
                        activePage={giftCardOffer?.current_page ? giftCardOffer?.current_page : 0}
                        itemsCountPerPage={giftCardOffer?.per_page ? giftCardOffer?.per_page : 0 }
                        totalItemsCount={giftCardOffer?.total ? giftCardOffer?.total : 0}
                        onChange={(pageNumber) => {
                          loadUser(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="p-first-page-link"
                        itemClassLast="p-last-page-link"
                        activeClass="p-active"
                        activeLinkClass="p-active-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
              }
        </div>
      </div>
    </>
  );
};

export default CardOfferComponent;