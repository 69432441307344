import { React, useEffect, useState } from 'react';

import Layout from '../components/Layout/Layout';
import baseApiUrl from '../utility/baseApiUrl';
const axios = require("axios");

const DevTest = () => {

    const [approved, setApproved] = useState([]);
    const [rejectedData, setRejectedData] = useState([]);

    const [totalDays, setTotalDays] = useState('');
   
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    
    const [dateLabel, setDateLabel] = useState([]);



    const dateLaDabeltaLoop = (number) =>{
         
        let i = 0;

        let labelDate = [];

        while (i <= number) { 

            let date = new Date(startDate).getDate()+i
            var mm = new Date(startDate).toLocaleString("en-US", { month: "short" });
            var yyyy = new Date(startDate).getFullYear();
            
            labelDate.push(`${date} ${mm} ${yyyy}`)

            if(i === number){
                setDateLabel(labelDate)
            }

            i++; 
        }

    }

    const approvedDataLoop = (number, approvedApiData) =>{
         
            let i = 0;
            // approvedData  loop
            let approvedData = approvedApiData;    
            let newApprovedData = [];

            while (i <= number) { 

                let date = new Date(startDate).getDate()+i

                let approvalItem =  approvedData.find(item => new Date(item.approval_date).getDate() == date);

                
                if(approvalItem){
                    newApprovedData.push(approvalItem?.total);

                }else{
                    newApprovedData.push(0);
                }

                if(newApprovedData.length === number){
                    setApproved(newApprovedData);                    
                }

                i++; 
            }

    }


    const rejectionLoop = (number, rejectionApiData) =>{
         
        let i = 0;

        let rejectionData = rejectionApiData;    
        let newRejectionData = [];

        while (i <= number) { 

            let date = new Date(startDate).getDate()+i

            let rejection =  rejectionData.find(item => new Date(item.rejection_date).getDate() == date);
            
            if(rejection){
                newRejectionData.push(rejection?.total);
            }else{
                newRejectionData.push(0);
            }

            if(newRejectionData.length === number){
                setRejectedData(newRejectionData);
            }

            i++; 
        }
    }
    


    const onSubmit = e =>{
       
        e.preventDefault();

        // Calculating the no. of days between two dates
        let diffInDays = '';

        const getNumberOfDays = (start, end) => {
    
            const date1 = new Date(start);
            const date2 = new Date(end);
        
            // One day in milliseconds
            const oneDay = 1000 * 60 * 60 * 24;
        
            // Calculating the time difference between two dates
            const diffInTime = date2.getTime() - date1.getTime();
        
            // Calculating the no. of days between two dates
             diffInDays = Math.round(diffInTime / oneDay);
           
            setTotalDays(diffInDays);
            return diffInDays;
        }
         
        getNumberOfDays(startDate, endDate);

        if(diffInDays){

            axios.post(baseApiUrl()+'chart-data?start_date=2022-07-10&end_date=2022-07-20').then(res =>{
    
                if(res.data){
                    approvedDataLoop(diffInDays, res.data.approved);
                    rejectionLoop(diffInDays, res.data.rajected);
                    dateLaDabeltaLoop(diffInDays);
                }
    
            }).catch(err =>{
                console.log('chart data error',err)
    
            })
        }


        
    }


  
    useEffect( () =>{
        
        let a = 3;
        console.log('date', a + 1)

    },[])


    return (
        <Layout>
            <div className='mt-10 mx-5'>

            <div>
                <div className='pb-8 lg:px-24 gap-10'>
                    <div className='pb-10 w-full bg-white rounded-md border shadow' >
                        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                            <h3>Date form</h3>

                            <button className='button__bg px-6 py-2 block rounded'>Date Form</button>

                        </div>
                        <form onSubmit={onSubmit} className='py-5 px-8'>
                            <div className=''>

                                <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                                    <div className="w-full mb-6">
                                        <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Start Date</label>
                                            <input type="date"
                                                value={startDate}
                                                onChange={e => setStartDate(e.target.value)}
                                                className="form-field" required />
                                        </div>

                                    </div>

                                    <div className="w-full mb-6">
                                        <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">End Date</label>
                                            <input type="date"
                                                value={endDate}
                                                onChange={e => setEndDate(e.target.value)}
                                                className="form-field"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                            <button type="submit" className="mt-5 text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                            </div>
                        </form>
                        

                        <div className='px-4 mt-6'>
                           <p>TotalDays : {totalDays && totalDays+1}</p>
                          
                           <p className='py-3'>Approved Data</p>

                           <div className='mt-5 flex flex-wrap gap-3'>
                            {approved?.map( (item, index ) =>{
                                return (
                                    <div key={index}>
                                        <p>{item}</p>
                                    </div>
                                )
                            })}
                           </div>

                           <p className='py-3'>Rejected Data</p>
                           <div className='mt-5 flex flex-wrap gap-3'>
                            {rejectedData?.map( (item, index ) =>{
                                return (
                                    <div key={index}>
                                        <p>{item}</p>
                                    </div>
                                )
                            })}
                           </div>

                           <div className='mt-5 flex flex-wrap gap-3'>
                            {dateLabel?.map( (item, index ) =>{
                                return (
                                    <div key={index}>
                                        <p>{item}</p>
                                    </div>
                                )
                            })}
                           </div>
                        </div>
                        
                    </div>

                </div>
            </div>
                
            </div>
        </Layout>
    );
};

export default DevTest;