import React from "react";
import { AiOutlineDashboard } from "react-icons/ai";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import './style.css';

const Sidebar = () => {
  const authUserPermission = useSelector((state) => state.authUserPermission);

  return (
    <div className='px-4 bg-white h-screen '>

      <div className="mt-5">
        <p>Dashboard</p>

        <NavLink to="/" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Dashboard
        </NavLink>

        {/* <p className=' flex menu_link items-center gap-4'>
                   <span className="menu__icon"><span className="menu__icon"><AiOutlineDashboard/></span></span> Setting
                </p> */}
      </div>

      {authUserPermission === 'admin' ? <div className="my-3 border-y py-5">
        <p>User Management</p>

        <NavLink to="/users" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          User Lists
        </NavLink>

        <NavLink to="/sellers" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Seller Lists
        </NavLink>

        <NavLink to="/create/user" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Add User
        </NavLink>
      </div> : <></>}


      <div className="border-b py-5">
        <p>Gift cards Management </p>
        <NavLink to="/gift-cards" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Gift Cards
        </NavLink>
        {authUserPermission === 'seller' ?
          <NavLink to="/add-gift-cards" className="menu__item flex menu_link gap-4 items-center ">
            <span className="menu__icon"><AiOutlineDashboard /></span>
            Add Gift Card
          </NavLink>
          : <></>
        }

      </div>


      {authUserPermission === 'admin' ? <div className="border-b py-5">
        <p>Transactions Management </p>
        <NavLink to="/transactions" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Transactions
        </NavLink>

        {/* <NavLink to="/create-transaction" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Add Transaction
        </NavLink> */}

      </div> : <></>}
      {authUserPermission === 'seller' ?

        <NavLink to="/my-transaction" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          My Tansaction
        </NavLink> : <></>}


        {authUserPermission === 'admin' ? <div className="border-b py-5">
        <p>Gift cards Offter Management </p>
        <NavLink to="/gift-card-offer" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Gift Card Offers
        </NavLink>

        <NavLink to="/add-gift-card-offer" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Add Gift Card Offer
        </NavLink>

      </div> :<></>}


    </div>
  );
};

export default Sidebar;