import { React, useEffect, useState } from 'react';
import Pagination from "react-js-pagination";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import apiStoragePath from '../../../utility/apiStoragePath';
import baseApiUrl from '../../../utility/baseApiUrl';

const axios = require("axios");

const MyTransactions = () => {

  const [transactions, setTransactions] = useState({});

  const [is_redeemUpdate, setIsRedeemUpdate] = useState('');
  const dispatch = useDispatch();
  const imagePath = apiStoragePath();

  // load  data from server
  const loadData = (pageNumber = 1) => {

    dispatch({
      type:"fullPageLoader_update",
      payload : true
    });

    axios
      .get(baseApiUrl()+'my-transaction?page='+pageNumber)
      .then((res) => {
        setTransactions(res.data.data);
        dispatch({
          type:"fullPageLoader_update",
          payload : false
        });
      })
      .catch((err) => {
        dispatch({
          type:"fullPageLoader_update",
          payload : false
        });
      })
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  useEffect(() => {
    loadData();
  }, []); 


  // redeemCode transactions
  const redeemCodeFunction = transactionItem => {

    dispatch({
      type:"fullPageLoader_update",
      payload : true
    });
    
    axios.post(baseApiUrl() + 'transaction-update/' + transactionItem.id).then(res => {
    
      dispatch({
        type:"fullPageLoader_update",
        payload : false
      });

      Toast.fire({
        icon: 'success',
        title: "REDEEM Status Updated."
      });

      loadData();
      
    }).catch(err =>{
     
      dispatch({
        type:"fullPageLoader_update",
        payload : false
      });
      
      console.log(err);

      Toast.fire({
          icon: 'error',
          title: 'server error'
        });
      })

  }

   let is_update = true
  // redeemCode transactions
  const redeemCode = transactionItem => {

    redeemCodeFunction(transactionItem);

    // setIsRedeemUpdate(transactionItem.id);
    
    // if(!is_redeemUpdate == transactionItem.id){
    //   redeemCodeFunction(transactionItem);
    // }


  }

  return (
    <Layout>
      <div className='bg-white rounded-lg '>


        <div className='px-4 py-3 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>My Transaction List</h3>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                Code
                </th>
                <th scope="col" className="px-6 py-3">
                 Transactions Amount
                </th>
                <th scope="col" className="px-6 py-3 ">
                 Redeem
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {transactions?.data?.map((item, index) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                   
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {item.code}
                    </th>


                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      $ {item.transaction_amount}
                    </th>
                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.is_redeem === 0 ? (

                       <div class="flex justify-center">
                          <label  for={item.code} class="inline-flex relative items-center cursor-pointer">
                            <input onChange={() =>redeemCode(item)} type="checkbox" value={item.code} id={item.code} class="sr-only peer"/>
                            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          </label>
                      </div>

                      ) : (
                       <div class="flex justify-center">
                          <label for={item.code} class="inline-flex relative items-center cursor-pointer">
                            <input onChange={() =>redeemCode(item)} type="checkbox" value={item.code} checked id={item.code} class="sr-only peer"/>
                            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          </label>
                      </div>
                      )}

                    </th>
                  </tr>
                )
              })}

            </tbody>
          </table>

          {transactions?.total > 5 && 
                <div className='pl-3 py-4'>
                    <Pagination
                        activePage={transactions?.current_page ? transactions?.current_page : 0}
                        itemsCountPerPage={transactions?.per_page ? transactions?.per_page : 0 }
                        totalItemsCount={transactions?.total ? transactions?.total : 0}
                        onChange={(pageNumber) => {
                          loadData(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="p-first-page-link"
                        itemClassLast="p-last-page-link"
                        activeClass="p-active"
                        activeLinkClass="p-active-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
              }
        </div>
      </div>
    </Layout>
  );
};

export default MyTransactions;