import React from 'react';
import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from '../hooks/ProtectedRoutes';
import Dashboard from './Dashboard/Dashboard';
import AddGiftCard from './GiftCard/AddGiftCard/AddGiftCard';
import EditGiftCard from './GiftCard/EditGiftCard/EditGiftCard';
import GiftCards from './GiftCard/GiftCards/GiftCards';
import AddGiftCardOffer from './GiftCardOffer/AddGiftCardOffer/AddGiftCardOffer';
import GiftCardOffers from './GiftCardOffer/GiftCardOffers/GiftCardOffers';
import EditGiftCardOffer from './GiftCardOffer/EditGiftCardOffer/EditGiftCardOffer';
import Login from './Login/Login';
import NoPageFound from './NoPageFound/NoPageFound';
import SellerLists from './SellerLists/SellerLists';
import CreateTransaction from './Transaction/CreateTransaction/CreateTransaction';
import EditTransaction from './Transaction/EditTransaction/EditTransaction';
import MyTransactions from './Transaction/Transactions/MyTransactions';
import Transactions from './Transaction/Transactions/Transactions';
import CreateUser from './UserManagement/CreateUser/CreateUser';
import EditUser from './UserManagement/EditUser/EditUser';
import Users from './UserManagement/Users/Users';
import DevTest from './DevTest';
function Master() {

  return (
    <div>

            <div className=''>
              
                  <Routes>
                  <Route path="/login" element={<Login />} />


                    {/* protection doone  */}
                    <Route element={<ProtectedRoutes/>}>
                        
                        <Route path="/" element={<Dashboard />} />
                        <Route path="create/user" element={<CreateUser />} />
                        <Route path="edit/user/:id" element={<EditUser />} />
                        <Route path="users" element={<Users />} />

                        <Route path="gift-cards" element={<GiftCards />} />
                        <Route path="add-gift-cards" element={<AddGiftCard />} />
                        <Route path="gift-card/:id" element={<EditGiftCard />} />

                        <Route path="sellers" element={<SellerLists />} />
                        <Route path="transactions" element={<Transactions />} />
                        <Route path="create-transaction" element={<CreateTransaction />} />
                        <Route path="edit-transaction/:id" element={<EditTransaction />} />

                        <Route path="my-transaction" element={<MyTransactions />} />


                        <Route path="gift-card-offer" element={<GiftCardOffers />} />
                        <Route path="add-gift-card-offer" element={<AddGiftCardOffer />} />
                        <Route path="edit-gift-card-offer/:id" element={<EditGiftCardOffer />} />

                        <Route path="/dev-test" element={<DevTest/>} />

                    </Route>
                   
                      {/* // 404 page  */}
                    <Route  path="*" element={<NoPageFound />}  />
                  </Routes>
            </div>

        </div>
  );
}

export default Master;