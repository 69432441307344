// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import baseApiUrl from '../../../utility/baseApiUrl';

const axios = require("axios");

const EditUser = () => {

  const dispatch = useDispatch();
  let { id } = useParams();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const [formTitle] = useState('Edit User');

  const [form, setForm] = useState({
    name: '',
    email: '',
    role: '',
    password: '',
    rate: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })

  }

  const onSubmit = e => {
    e.preventDefault();
    dispatch({
      type: "fullPageLoader_update",
      payload: true
    });

    // dispatch(SET_PAGE_LOADER());



    axios
      .put(baseApiUrl() + 'users/' + id, form)
      .then(res => {

        Toast.fire({
          icon: 'success',
          title: 'User update success'
        });
        dispatch({
          type: "fullPageLoader_update",
          payload: false
        });

      })
      .catch((err) => {
        Toast.fire({
          icon: 'error',
          title: 'Server error'
        });

        dispatch({
          type: "fullPageLoader_update",
          payload: false
        });

      });
  }



  // }).catch ((err) => {

  // load user from server
  const loadUser = () => {

    dispatch({
      type: "fullPageLoader_update",
      payload: true
    });

    axios
      .get(baseApiUrl() + "users/" + id)
      .then((res) => {
        dispatch({
          type: "fullPageLoader_update",
          payload: false
        });

        setForm(res.data)
      }).catch(err =>{
        Toast.fire({
          icon: 'error',
          title: 'Server error'
        });

        console.log('error', err)
      })

    }


    // load user from server
    

    useEffect(() => {
      loadUser();
    }, []);

    return (
      <Layout>
        <div>
          <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
              <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                <h3>{formTitle}</h3>

                <Link to={'/users'}>
                  <button className='button__bg px-6 py-2 block rounded'>User Lists</button>
                </Link>
              </div>
              <form onSubmit={onSubmit} className='py-5 px-8'>
                <div className=''>

                  <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                    <div className="w-full mb-6">
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">User Name</label>
                        <input type="text"
                          name='name'
                          value={form.name}
                          onChange={handleChange}
                          className="form-field" required />
                      </div>

                    </div>

                    <div className="w-full mb-6">
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
                        <input type="email"
                          name='email'
                          value={form.email}
                          onChange={handleChange}
                          className="form-field"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                    <div className="w-full mb-6">
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password </label>
                        <input type="password"
                          name='password'
                          value={form.password}
                          onChange={handleChange}
                          className="form-field" />
                      </div>
                    </div>

                    <div className="">
                      <label className="form-label">User Role</label>
                      <select name='role' onChange={handleChange} value={form.role} className="select-field" required>
                        <option value="">Select</option>
                        <option value="manager">Manager</option>
                        <option value="seller">Seller</option>
                      </select>
                    </div>

                  </div>

                  {/* {form.role === 'seller' ? <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                    <div className="w-full mb-6">
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Rate % </label>
                        <input type="number"
                          name='rate'
                          value={form.rate}
                          onChange={handleChange}
                          className="form-field" required />
                      </div>
                    </div>



                  </div> : <></>} */}


                  <button type="submit" className="mt-3 text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </Layout>
    );
  };

  export default EditUser;