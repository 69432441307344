import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { Link, Navigate } from 'react-router-dom';
import baseApiUrl from '../../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import Item from 'antd/lib/list/Item';

const axios = require("axios");

const AddGiftCardOffer = () => {
   
    const dispatch = useDispatch();

    const ref = useRef();


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Add Gift Card Offer');
    
    const [title, setTitle] = useState(''); // changeHere - stateName correction
    const [is_active, setIsActive] = useState(''); // changeHere - stateName correction
   
    const [rate_type, setRateType] = useState('0'); // changeHere - stateName correction
    const [rate, setRate] = useState(''); // changeHere - stateName correction
  
    const [price_type, setPriceType] = useState('1'); // changeHere - stateName correction
    const [min_price, setMinPrice] = useState(''); // changeHere - stateName correction
    const [max_price, setMaxPrice] = useState(''); // changeHere - stateName correction
    const [fixed_price_array, setFixedPriceArray] = useState([]); // changeHere - stateName correction
    const [fixed_price, setFixedPrice] = useState(null); // changeHere - stateName correction


   
    let fixedPriceData = [];

    const pushFixedPrice = () =>{

        if(fixed_price === null){

            Toast.fire({
                icon: 'error',
                title: 'Enter price'
              });

        }else{
            // fixedPriceData.push(fixed_price)
            // setFixedPriceArray([...fixedPriceData,...fixed_price_array]);
            // setFixedPrice('');
            // console.log('setFixedPriceArray', fixedPriceData)

            const tem_fixed_price_array = [...fixed_price_array];
            console.log('tem_fixed_price_array_1', tem_fixed_price_array);
            tem_fixed_price_array.push(fixed_price);

            console.log('tem_fixed_price_array_2', tem_fixed_price_array)
            setFixedPriceArray(tem_fixed_price_array);
            console.log('setFixedPriceArray', fixed_price_array);
            setFixedPrice('');
            setFixedPrice(null);
        }
    }


     // remove FixedPrice function
     const removFixedPrice = priceItemIndex =>{
       
        const tem_fixed_price_array = [...fixed_price_array];
        tem_fixed_price_array.splice(priceItemIndex, 1);

        setFixedPriceArray(tem_fixed_price_array);

    };


    
    // handleMultipleImage
    let giftcardImages = [];

    const [galleryImages, setGalleryImages] = useState([]);

    const handleMultipleImage = e => {

        let droppedFiles = e.target.files;

        if (!droppedFiles) return;

        ([...droppedFiles]).forEach(f => {
            giftcardImages.push(f);
        });

        setGalleryImages([...giftcardImages,...galleryImages]);

    }


    // remove Gallery Image function
    const removeGalleryImage = image =>{
        let index = galleryImages.indexOf(image);
                    galleryImages.splice(index, 1);

        let p_Galley_Im_i = giftcardImages.indexOf(image);
                            giftcardImages.splice(p_Galley_Im_i, 1);

        setGalleryImages([...giftcardImages,...galleryImages]);
    };


    const resetForm = () =>{
      
        setTitle('');
       
        setRateType('');
        setRate('');

        setPriceType('');
        setMinPrice('');
        setMaxPrice('');
        setFixedPrice('');
        setFixedPriceArray('');

        setIsActive('');

        // ref.current.value = "";
        setGalleryImages([]);
    }

     
     const onSubmit = e =>{

        dispatch({
            type:"fullPageLoader_update",
            payload : true
          });

        e.preventDefault();

        let data = new FormData();
        data.append('title', title);
        data.append("is_active", is_active);
        data.append('price_type', price_type);
        data.append('min_price', min_price);
        data.append('max_price', max_price);

        data.append('rate_type', rate_type);
        data.append('rate', rate);

        data.append('fixed_price', JSON.stringify(fixed_price_array));

        if (galleryImages) {

            for(let i = 0; i < galleryImages.length; i++){
                 let file = galleryImages[i];
                 data.append('gallery['+ i + ']', file || '');
             }
        };


        axios.post(baseApiUrl() + 'gift-card-offer', data).then(res => {
           
            console.log(res)
            
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

              dispatch({
                type:"fullPageLoader_update",
                payload : false
              });

              resetForm();

        }).catch((err) => {

            dispatch({
                type:"fullPageLoader_update",
                payload : false
              });
              
            console.log(err);

            Toast.fire({
                icon: 'error',
                title: 'server error'
              });
        });

        console.log(data)
     }

    return (
        <Layout>
        <div>
        <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                   <h3>{formTitle}</h3>
                   <Link to={'/gift-card-offer'}>
                     <button className='button__bg px-6 py-2 block rounded'>Card Offer List</button>
              </Link>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>
                   <div className=''>

                       <div className=''>
                           <div className="w-full mb-6">
                               <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gift Card Title</label>
                                <input type="text" 
                                       value={title}
                                       onChange={e => setTitle(e.target.value)} 
                                className="form-field" required />
                           </div>

                           <div className="w-full mb-6">
                               <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>
                                   <div>
                                       {/* <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Image</label>
                                           
                                            <input  multiple required type="file" ref={ref} className='border-2 rounded py-1 px-1 w-full'
                                                onChange={handleMultipleImage} 
                                                onClick={e => (e.target.value = null)}
                                            />
                                        </div> */}

                                        <div className="mt-6">
                                                <label className="form-label">Card Status</label>
                                                <select onChange={e => setIsActive(e.target.value)} value={is_active} className="select-field" required >
                                                    <option value="">Select</option>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Active</option>
                                                </select>
                                            </div>
                                       </div>

                                 

                                   <div className='flex justify-end '>
                                         {/* show gallery images */}
                                         <div className="mt-5 flex flex-wrap gap-3">
                                            {galleryImages?.map((item, index) => 
                                              
                                              <div key={index} className="">
                                                        <div className="relative ">
                                                        <img src={URL.createObjectURL(item)} alt="" className="w-24 h-20 shadow-md  " />
                                                        <span onClick={() => removeGalleryImage(item)} className=" text-center cursor-pointer absolute left-0 top-0 bg-red-500 text-sm text-white rounded-full pt-0.5 h-6 w-6 ">X</span>
                                                        </div>
                                               </div>)}
                                        </div>
                                   </div>
                               </div>
                               
                           </div>

                           <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                                     <div className='my-6 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>
                                       
                                          <div className="w-full">
                                                <label className="form-label">Price Type</label>
                                                <select onChange={e => setPriceType(e.target.value)} value={price_type} className="select-field" required >
                                                    <option value="0">Range</option>
                                                    <option value="1">Fixed</option>
                                                </select>
                                            </div>

                                            {price_type === '0' ? (
                                              
                                              <div className='flex gap-3 '>
                                                    <div>
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Min Price</label>
                                                        <input type="number" 
                                                            value={min_price}
                                                            onChange={e => setMinPrice(e.target.value)} 
                                                        className="form-field" required />
                                                    </div>

                                                    <div>
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Max Price</label>
                                                        <input type="number" 
                                                            value={max_price}
                                                            onChange={e => setMaxPrice(e.target.value)} 
                                                        className="form-field" required />
                                                    </div>

                                              </div> 
                                          
                                            ):(

                                                <div className="flex gap-2">
                                                    <div>
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Fixed Price</label>
                                                        <input type="number" 
                                                            value={fixed_price}
                                                            onChange={e => setFixedPrice(e.target.value)} 
                                                        className="form-field" />
                                                    </div>

                                                   <p onClick={pushFixedPrice} className="mt-6 text-center cursor-pointer pb-2 pt-3 px-3 rounded text-white btn-bg">Add </p>
                                                </div>
                                            )}

                                       
                                       {price_type === '1' && 
                                           <>
                                             {fixed_price_array.length ? 
                                                    <div className='flex flex-wrap gap-3'>
                                                        {fixed_price_array?.map((item, index) =>{
                                                            return(
                                                                <div key={index}>
                                                                        <p className='flex gap-3 items-center rounded border px-4 py-1 '>
                                                                            {item}
                                                                            <span onClick={() => removFixedPrice(index)} className='text-red-500 cursor-pointer ' >X</span>
                                                                        </p>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            : ''}
                                           </>
                                            
                                       }
                                        
                                     </div>

                                     <div className='my-6 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>
                                       
                                          <div className="w-full">
                                                <label className="form-label">Rate Type</label>
                                                <select onChange={e => setRateType(e.target.value)} value={rate_type} className="select-field" required >
                                                    <option value="0">Percentage</option>
                                                    <option value="1">Fixed</option>
                                                </select>
                                            </div>


                                            <div className="">
                                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{rate_type === '0' ? 'Percentage' : 'Fixed'}</label>
                                                    <input type="text" 
                                                        value={rate}
                                                        onChange={e => setRate(e.target.value)} 
                                                    className="form-field" required />
                                            </div>
                                     </div>

                               </div>

                       </div>

                       <div className='flex flex-wrap gap-3 '>
                            <button type="submit" className="save-btn form__btn__bg">Save</button>

                            <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                       </div>
                   </div>
               </form>
            </div>

        </div>
      </div>
      </Layout>
    );
};

export default AddGiftCardOffer;