import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import baseApiUrl from "../../../utility/baseApiUrl";
import './style.css';
const TopNavBar = () => {
   const authUserPermission = useSelector((state) => state.authUserPermission);
   const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);
   const [sellerDue,setSellerDue]= useState(0);
   const dispatch = useDispatch();
   const handleLogoutOnClik = () => {
      localStorage.clear();
      // logout();
      dispatch({
         type: "login_status_update",
         payload: false
      })
      dispatch({
         type: "loggedinUserInfo_update",
         payload: {}
      })
      dispatch({
         type: "tokenValue_update",
         payload: ""
      })
      //     alert("hello")

      // dispatch(loggedInUser(true))
      // setOpen(false);
   };
   function loadBody() {

      // const menuicon = document.getElementById("menuicon");
      // const phone_menu_icon = document.getElementById("phone_menu_icon");

      const sidebar = document.getElementById("sidebar");

      // const activeMenu = document.querySelector(".active__menu");

      // menuicon.addEventListener("click", function() {
      sidebar.classList.toggle('toggle__Sidebar');
      // });

      // phone_menu_icon.addEventListener("click", function() {
      //   sidebar.classList.toggle('toggle__phone__Sidebar');
      // });
   }
   function loadBodyMobile() {

      // const menuicon = document.getElementById("menuicon");
      // const phone_menu_icon = document.getElementById("phone_menu_icon");

      const sidebar = document.getElementById("sidebar");

      // const activeMenu = document.querySelector(".active__menu");

      // menuicon.addEventListener("click", function() {
      //   sidebar.classList.toggle('toggle__Sidebar');
      // });

      // phone_menu_icon.addEventListener("click", function() {
      sidebar.classList.toggle('toggle__phone__Sidebar');
      // });
   }

   const getSellerDue = () => {


      axios.get(baseApiUrl() + 'seller-due')
      .then(res => {
         console.log("test_seller_due", res.data.due);
         setSellerDue( res.data.due);

      })

   }
   useEffect(()=>{
      if(authUserPermission==='seller'){
         getSellerDue();
      }
      
   },[])
   return (
      <div className="bg-white flex justify-between px-5 py-6">
         <div className="flex justify-between lg:gap-28 md:gap-16 gap-8 items-center ">

            {/* logo */}
            <div className="  ">
               <Link to={'/'}>
                  <img width="95px" height="60px" src="https://ozchest.com/static/media/Logo.e1bbcb99de4ca7b833d1.PNG" alt="PAKBET TV" />
               </Link>
            </div>


            <div className="flex items-center gap-5">
               <div onClick={loadBody} id="menuicon" className=" lg:block md:block  hidden  text-lg cursor-pointer light__bg rounded-md p-2">
                  <AiOutlineMenu />
               </div>

               <div onClick={loadBodyMobile} id="phone_menu_icon" className="lg:hidden md:hidden  block text-lg cursor-pointer light__bg rounded-md p-2">
                  <AiOutlineMenu />
               </div>


               {/* search box */}
               {/* <div className="lg:block md:block  hidden "> 
               <div className="flex items-center border-2 rounded-lg">
                  <button className="flex items-center justify-center px-4 border-r">
                        <svg className="w-6 h-6 text-gray-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 24 24">
                           <path
                              d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z">
                           </path>
                        </svg>
                  </button>
                  <input type="text" className="px-4 py-2 block" placeholder="Search..." />
               </div>
            </div> */}
            </div>

         </div>

         <div className="flex items-center gap-5">

            <div className="light__bg rounded-md p-2 text-xl">
               {loggedinUserInfo.name}
            </div>
            {authUserPermission==='seller' && <span>
               Due: {sellerDue}
            </span>}
            
            <button onClick={handleLogoutOnClik} className="light__bg rounded-md p-2">

               <FiLogOut />
            </button>

            {/* <div>
            <img src="https://berrydashboard.io/static/media/user-round.13b5a31b.svg" alt="" />
          </div> */}
         </div>

      </div>
   );
};

export default TopNavBar;
