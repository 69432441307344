import React from 'react';
import Sidebar from './Sidebar/Sidebar';
import TopNavBar from './TopNavBar/TopNavBar';

const Layout = ({ children }) => {
    return (
        <div>

            <TopNavBar></TopNavBar>

            <div className='flex relative layout-responsive-container'>

                <div id="sidebar" className='sidebar'>
                    <Sidebar></Sidebar>
                </div>

                <div className='content__body'>
                    {children}
                </div>

            </div>

        </div>
    );
};

export default Layout;