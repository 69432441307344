import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import useAuth from '../../hooks/useAuth';
import CardOfferComponent from './DashBoardComponents/CardOfferComponent';
import FormForChart from './FormForChart';
const Dashboard = () => {

    const auth = useAuth();
    const dispatch = useDispatch();
    const authUserPermission = useSelector((state) => state.authUserPermission);

    // useEffect( () =>{
    //     dispatch({type: 'Full_Page_Loader'});
    // })

    return (
        auth ? <>
            <div className=''>
                <Layout>
                    <div className="main bg-white border-2 border-gray-300 rounded ">

                        <div className="rounded-t border-b-2 border-gray-300" style={{ backgroundColor: '#F0F3F5' }}>
                            <h1 className="p-4">Dashboard</h1>
                        </div>
                        <div className="p-4">
                            <p className="mb-4"> Welcome to OZchest Adminstration.</p>
                            <hr />

                        </div>

                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>



                        <div className='-mt-12 px-4 py-6'>
                            {/* {
                            authUserPermission!=='seller' && <FormForChart />
                        } */}
                            <div className='my-8 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                                <CardOfferComponent />

                            </div>

                            <FormForChart />



                        </div>

                    </div>

                    {/* <FormForChart/> */}
                </Layout>
            </div>
        </> : <Navigate to="/login" />
    );
};

export default Dashboard;