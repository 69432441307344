import { React, useEffect, useState } from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale,LinearScale,PointElement, LineElement,Title,Tooltip,Legend
);

const LineChart = ({approved, rejected, dateLabel}) => {
   
    return (
      <Line
          data={{
            labels:dateLabel,
            datasets: [
              {
                label: 'Approved',
                data: approved,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                tension: 0.5,
                borderWidth: 2,
              },
              {
                label: 'Rejected',
                data: rejected,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                tension: 0.5,
              }
              
            ],
          }}
      
          options={{
            responsive: true,
            plugins: {
              legend: {
              display: true,
              position: 'bottom',
              },
              title: {
                display: true,
                text: 'Chart.js Line Chart',
              },
            },
            scales:{
              x:{
                grid:{
                  display: false,
                }
              }
            }
          }}
      />
    );
};

export default LineChart;